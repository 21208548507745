/*  license-sua.jsx
    license-sua-2.jsx */
.license-sua-img{
    text-align: center;
    padding-bottom: 20px;
}
@media only screen and (max-width: 991px) {
    .license-sua-order{
        order: 2;
    }     
}

/* category.jsx */
@media only screen and (min-width: 992px) {
    .category-border:nth-child(n){
        border-right: 1px solid #000;
    }
    .category-border:nth-last-child(1){
        border-right: 0px;
    }
}

@media only screen and (max-width: 991px) {
    .category-border:nth-child(odd){
        border-right: 1px solid #000;
    } 
    .category-border:nth-child(even){
        border-right: 0px;
    } 
}

@media only screen and (max-width: 575px) {
    .category-border:nth-child(odd){
        border-right: 0px;
    } 
    .category-border:nth-child(even){
        border-right: 0px;
    }
}

/* course.jsx */
/* Regular styles for .btnStyle */
.btnStyle {
    background-color: #F68B1F;
    color: #fff;
    font-weight: 700;
    padding: 10px 30px;
    letter-spacing: 1.25;
}
.btnStyle-two {
    background-color: grey;
    color: #fff;
    font-weight: 700;
    padding: 10px 30px;
    letter-spacing: 1.25;
}